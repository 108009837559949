module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/npmscopes.site.gs.com/gatsby-plugin-canonical-urls/2.1.0_gatsby@2.24.92/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://marquee.gs.com"},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/npmscopes.site.gs.com/gatsby-plugin-use-query-params/1.0.1_19305530fcdff0a919813446b7a5ad21/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/npmscopes.site.gs.com/gatsby-plugin-google-analytics/4.6.0_666ad3a712df41e8e6ea47d7c4d14237/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-96382331-16","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
