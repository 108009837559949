// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaigns-tsx": () => import("./../../../src/pages/campaigns.tsx" /* webpackChunkName: "component---src-pages-campaigns-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-campaign-tsx": () => import("./../../../src/templates/campaign.tsx" /* webpackChunkName: "component---src-templates-campaign-tsx" */),
  "component---src-templates-custom-tsx": () => import("./../../../src/templates/custom.tsx" /* webpackChunkName: "component---src-templates-custom-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/product-category.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

