// This file redirects user to the QA login page
// if users have invalid MarqueeCdnAccess Cookie (Akamai CDN Cookie).
import { getEncodedUrl } from '@mq/aurora-login/dist/url-helpers';

const host = window.location && window.location.host;
const href = window.location && window.location.href;


// Don't check the cookie if we're either running locally / in develop
// or if we're in the public site
const isPublic = process.env.GATSBY_AUDIENCE_LEVEL.toLowerCase() === 'public';
const isDevelop =
  process.env.GATSBY_ENV &&
  process.env.GATSBY_ENV.toLowerCase() === 'development';

if (!isPublic && !isDevelop) {
  console.log(process.env.GATSBY_AUDIENCE_LEVEL, isPublic, isDevelop);
  fetch(`https://cdn.marquee.gs.com/cms/protected/protected-asset.png`, {
    method: 'GET',
    credentials: 'include',
    cache: 'no-store',
  }).then(function doLogin(response) {
    if (response.status !== 200) {
      window.location.assign(`/l/?From=${getEncodedUrl(href)}`);
    }
  });
}
